import styled from "styled-components";
import * as yup from "yup";
import { Link, router, usePage } from "@inertiajs/react";
import * as React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import logo from "@/assets/LogoFooter.png";
import logowebp from "@/assets/LogoFooter.webp";
import registerimage from "@/assets/resgisterimage.png";
import registerimagewebp from "@/assets/resgisterimage.webp";
import VisibilityOn from "@/assets/visibility.svg";
import VisibilityOff from "@/assets/visibility_off.svg";
import { FiX } from "react-icons/fi";
import toast from "react-hot-toast";
import logofooter2webp from "@/assets/LogoFooter2.webp";
import logofooter2 from "@/assets/LogoFooter2.png";
import WebPImage from "@/components/WebPImage";

import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { Button } from "@nextui-org/react";
import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@/store/store";

type FormData = {
    email: string;
    first_name: string;
    last_name: string;
    password: string;
    password_confirmation: string;
};

const registerSchema = yup
    .object({
        first_name: yup.string().required(),
        last_name: yup.string().required(),
        email: yup.string().required().email(),
        password: yup.string().required().min(6),
        password_confirmation: yup
            .string()
            .required()
            .min(6)
            .oneOf([yup.ref("password")], "Passwords must match"),
    })
    .required();

type RegisterProps = {
    closeModal: () => void;
    openLoginModal: () => void;
};

const StyledRegister = styled.div`
    background-repeat: no-repeat;
    background-size: cover;

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-text-fill-color: white !important;
        transition: background-color 5000s ease-in-out 0s;
    }
`;

const Register: React.FC<RegisterProps> = ({ closeModal, openLoginModal }) => {
    const { t } = useTranslation(["common", "validation", "glossary"]);
    const registerSchema = yup
        .object({
            first_name: yup.string().required(t('validation:error-name')),
            last_name: yup.string().required(t('validation:error-lastname')),
            email: yup.string().required(t('validation:required-email')).email(t('validation:error-email')),
            password: yup.string().required(t('validation:error-password')).min(6, t('validation:type-password', { min: 6 })),
            password_confirmation: yup
                .string()
                .required(t('validation:error-password'))
                .oneOf([yup.ref("password")], t('validation:error-confirm-pass')),
        })
        .required();

    const { executeRecaptcha } = useGoogleReCaptcha()
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<FormData>({ resolver: yupResolver(registerSchema) });
    const { locale } = usePage().props;
    const loading = useSelector((state: RootState) => state.plan.loading)
    const [passwordVisible, setPasswordVisible] = useState<boolean>(false)
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState<boolean>(false)

    const onSubmit = useCallback(
        (data: FormData) => {

            if (!executeRecaptcha) {
                console.log("Execute recaptcha not yet available");
                return;
            }
            executeRecaptcha("form").then((gReCaptchaToken: string) => {
                submitForm(gReCaptchaToken, data);
            });
        },
        [executeRecaptcha]
    );

    const submitForm = (gReCaptchaToken: string, data: FormData) => {

        router.post(`/${locale}/register`,
            {
                ...data,
                "g-recaptcha-response": gReCaptchaToken
            },
            {
                onSuccess: () => {
                    closeModal();
                    toast.success(t("register_success", { ns: "validation" }));
                },
            });
    };



    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const toggleConfirmPasswordVisibility = () => {
        setConfirmPasswordVisible(!confirmPasswordVisible);
    };

    return (
        <StyledRegister>
            <div className="h-screen flex bg-[#070F2B]">
                <button
                    className="absolute text-white top-12 right-12"
                    onClick={closeModal}
                >
                    <FiX size={40} />
                </button>
                <div
                    className="xl:w-1/2 w-full h-full flex items-center justify-center relative"
                    style={{
                        backgroundImage: `url(${registerimage})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center left",
                    }}
                >
                    <button
                        className="absolute block xl:hidden text-white top-6 right-6"
                        onClick={closeModal}
                    >
                        <FiX size={34} />
                    </button>
                    <div className="absolute top-0 left-0 w-full h-full pointer-events-none xl:bg-gradient-to-l from-[#070F2B]"></div>
                    <div className="p-8 rounded-lg xl:max-w-xl md:max-w-2xl w-full relative z-10">
                        <div className="xl:hidden flex justify-center mb-8">
                            <WebPImage
                                className="h-16 mb-8"
                                srcWebP={logowebp}
                                srcFallback={logo}
                                alt="Thassos Greece"
                            />
                        </div>

                        <h1 className="text-white font-bold text-3xl mb-4">
                            {t("register", { ns: "glossary" })}
                        </h1>
                        <p className="text-white font-thin text-lg mb-8">
                            {t("is_member", { ns: "glossary" })}{" "}
                            <span
                                className="ml-1 underline font-bold cursor-pointer"
                                onClick={() => {
                                    closeModal();
                                    openLoginModal();
                                }}
                            >
                                {t("login", { ns: "glossary" })}
                            </span>
                        </p>

                        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
                            <div className="grid grid-cols-2 gap-4">
                                <div>
                                    <input
                                        id="first_name"
                                        type="text"
                                        placeholder={
                                            t("name", {
                                                ns: "glossary",
                                            }) + " *"
                                        }
                                        {...register("first_name")}
                                        className="p-3 w-full bg-[#6d7079] rounded-[15px] backdrop-blur-[50px] text-white placeholder-white"
                                    />
                                    {errors.first_name && (
                                        <p className="text-red-500 text-sm">
                                            {errors.first_name?.message}
                                        </p>
                                    )}
                                </div>
                                <div>
                                    <input
                                        id="last_name"
                                        type="text"
                                        placeholder={
                                            t("surname", {
                                                ns: "glossary",
                                            }) + " *"
                                        }
                                        {...register("last_name")}
                                        className="p-3 w-full bg-[#6d7079] rounded-[15px] backdrop-blur-[50px] text-white placeholder-white"
                                    />
                                    {errors.last_name && (
                                        <p className="text-red-500 text-sm">
                                            {errors.last_name?.message}
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div>
                                <input
                                    id="email"
                                    type="email"
                                    placeholder="email *"
                                    {...register("email")}
                                    className="p-3 w-full bg-[#6d7079] rounded-[15px] backdrop-blur-[50px] text-white placeholder-white"
                                />
                                {errors.email && (
                                    <p className="text-red-500 text-sm">
                                        {errors.email?.message}
                                    </p>
                                )}
                            </div>

                            <div className="relative">
                                <input
                                    id="password"
                                    type={passwordVisible ? "text" : "password"}
                                    placeholder={
                                        t("password", {
                                            ns: "glossary",
                                        }) + " *"
                                    }
                                    {...register("password")}
                                    className="p-3 w-full bg-[#6d7079] rounded-[15px] backdrop-blur-[50px] text-white placeholder-white"
                                />
                                <button
                                    type="button"
                                    onClick={togglePasswordVisibility}
                                    className="absolute inset-y-0 right-0 px-3 text-white"
                                >
                                    <img
                                        src={
                                            passwordVisible
                                                ? VisibilityOff
                                                : VisibilityOn
                                        }
                                        alt="Toggle visibility"
                                        style={{ filter: "invert(100%)" }}
                                    />
                                </button>
                                {errors.password && (
                                    <p className="text-red-500 text-sm">
                                        {errors.password?.message}
                                    </p>
                                )}
                            </div>
                            <div className="relative">
                                <input
                                    id="password_confirmation"
                                    type={
                                        confirmPasswordVisible
                                            ? "text"
                                            : "password"
                                    }
                                    placeholder={t("password_confirm", {
                                        ns: "glossary",
                                    })}
                                    {...register("password_confirmation")}
                                    className="p-3 w-full bg-[#6d7079] rounded-[15px] backdrop-blur-[50px] text-white placeholder-white"
                                />
                                <button
                                    type="button"
                                    onClick={toggleConfirmPasswordVisibility}
                                    className="absolute inset-y-0 right-0 px-3 text-white"
                                >
                                    <img
                                        src={
                                            confirmPasswordVisible
                                                ? VisibilityOff
                                                : VisibilityOn
                                        }
                                        alt="Toggle visibility"
                                        style={{ filter: "invert(100%)" }}
                                    />
                                </button>
                                {errors.password_confirmation && (
                                    <p className="text-red-500 text-sm">
                                        {errors.password_confirmation?.message}
                                    </p>
                                )}
                            </div>
                            <Button
                                isLoading={loading}
                                disabled={loading}
                                title={t("register", { ns: "glossary" })}
                                type="submit"
                                className="w-2/5 py-7 px-4 text-white text-lg font-medium bg-[#1D24CA] rounded-[10px] hover:bg-blue-700"
                            >
                                {t("register", { ns: "glossary" })}
                            </Button>
                        </form>
                    </div>
                </div>
                <div className="w-1/2 h-full bg-[#070F2B] hidden xl:flex items-center justify-center">
                    <WebPImage
                        className="h-30"
                        srcWebP={logowebp}
                        srcFallback={logo}
                        alt="Thassos Greece"
                    />
                </div>
            </div>
        </StyledRegister>
    );
};

export default Register;
